.box-blog {
    font-family: 'Be Vietnam Pro', sans-serif;
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr 4fr;
}

.about-me {
    height: 100vh;
    grid-column: 1;
    background-color: #C8C8C8;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding-right: 1rem;
}

.about-me .title {
    font-family: 'Roboto Mono', monospace;
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
}

.about-me .logo {
    border: 2px solid #FFF;
    width: 8.5rem;
    height: 8.5rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    background-size: cover;
}

.about-me .author {
    font-family: 'Roboto Mono', monospace;
    padding-bottom: 1rem;
    border-bottom: 3px solid #E8E9EB;
    color: #363636;
}

.about-me .description {
    text-align: right;
    padding-top: 0.75rem;
    padding-left: 0.2rem;
    line-height: 1.75rem;
    color: #363636;
}

.list-blog {
    padding: 1rem;
    height: 100vh;
    overflow-y: scroll;
}

.list-blog .time {
    font-size: 0.75rem;
    color: rgb(195, 195, 195);
    padding-right: 0.3rem;
}

.list-blog .tag {
    font-size: 0.8rem;
}

.item-blog {
    padding-bottom: 1rem;
}

.item-blog .title-article .link-article{
    text-decoration: none;
    color: inherit;
}

.item-blog .title-article .link-article:hover{
    text-decoration: underline;
}

.preloader {
    align-items: center;
    background: rgb(23, 22, 22, 0.6);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.3s linear;
    width: 100%;
    z-index: 9999;
  }

@media (max-width: 480px) {
    .about-me {
        grid-column: 5;
    }
    .list-blog {
        grid-column: 5;
        background-color: rgba(34, 34, 34, 0.8);
        min-height: 500px;
    }
}
